<template>
  <div class="root">
    <div class="about text-center">
      <h1 class="main_title">{{ lang.contact_title }}</h1>
      <p class="my-4">
        <strong>{{ lang.vos_contacts }}</strong>
      </p>
      <hr />
      <div class="text-start">
        <select
          name="departement"
          v-model="departement"
          class="form-select"
          :placeholder="lang.placeholder_departement"
        >
          <option value="" disabled>{{ lang.placeholder_departement }}</option>
          <option
            :value="d.num_dep"
            v-for="d in departements"
            :key="d.dep_name"
          >
            {{ d.dep_name }}
          </option>
        </select>
        <p>&nbsp;</p>
        <h4 class="mb-4" v-if="departement != ''">
          {{ lang.votre_sft }}
        </h4>
        <p
          v-if="
            inArray(departement, [
              '54',
              '55',
              '57',
              '67',
              '68',
              '88',
              '70',
              '90',
              '25',
              '39',
            ])
          "
        >
          <strong>Didier BERTRAND</strong><br />dbertrand@fga.cfdt.fr<br />
          06 70 64 83 34
        </p>
        <p
          v-if="
            inArray(departement, [
              '03',
              '63',
              '15',
              '42',
              '43',
              '69',
              '01',
              '38',
              '73',
              '74',
            ])
          "
        >
          <strong>Christine BESNARD-COLLIN</strong
          ><br />cbesnardcollin@fga.cfdt.fr<br />
          06 87 35 91 71
        </p>
        <p
          v-if="
            inArray(departement, [
              '28',
              '45',
              '41',
              '18',
              '37',
              '36',
              '86',
              '79',
              '16',
              '17',
            ])
          "
        >
          <strong>Laurent CHERIGNY</strong><br />lcherigny@fga.cfdt.fr<br />
          06 73 42 75 90
        </p>
        <p
          v-if="
            inArray(departement, [
              '40',
              '64',
              '32',
              '65',
              '31',
              '81',
              '09',
              '11',
              '66',
            ])
          "
        >
          <strong>Laurent CORREGE</strong><br />lcorrege@fga.cfdt.fr<br />
          06 07 64 02 83
        </p>
        <p
          v-if="
            inArray(departement, [
              '62',
              '59',
              '80',
              '60',
              '02',
              '08',
              '51',
              '10',
              '52',
              '89',
              '21',
              '58',
              '71',
            ])
          "
        >
          <strong>Pierre DELAGRANGE</strong><br />pdelagrange@fga.cfdt.fr<br />
          06 37 29 98 41
        </p>
        <p
          v-if="
            inArray(departement, [
              '87',
              '23',
              '24',
              '19',
              '33',
              '47',
              '82',
              '46',
              '12',
            ])
          "
        >
          <strong>Stéphane GRESSET</strong><br />sgresset@fga.cfdt.fr<br />
          06 60 29 44 31
        </p>
        <p
          v-if="
            inArray(departement, [
              '29',
              '22',
              '56',
              '35',
              '44',
              '85',
              '53',
              '49',
              '72',
            ])
          "
        >
          <strong>Annick LE GUEVEL</strong><br />aleguevel@fga.cfdt.fr<br />
          06 33 17 68 42
        </p>
        <p
          v-if="
            inArray(departement, [
              '50',
              '14',
              '61',
              '76',
              '27',
              '91',
              '92',
              '93',
              '94',
              '95',
              '971',
              '972',
              '973',
              '974',
              '976',
            ])
          "
        >
          <strong>Elisabeth RUEL</strong><br />eruel@fga.cfdt.fr<br />
          07 72 29 53 40
        </p>
        <p
          v-if="
            inArray(departement, [
              '48',
              '34',
              '30',
              '07',
              '26',
              '05',
              '84',
              '04',
              '13',
              '83',
              '06',
              '2A',
              '2B',
            ])
          "
        >
          <strong>David VEYER</strong><br />dveyer@fga.cfdt.fr<br />
          06 83 03 06 16
        </p>

        <hr />
        <img src="../assets/carte.jpg" alt="Carte des SFT" class="d-block m-auto" />
        <hr />
        <p>© 2021 CFDT - {{lang.copyright}}</p>
      </div>
    </div>
  </div>
</template>

<style scoped>
.about {
  width: 100%;
}
.big {
  font-size: 1.8rem;
}
img {
  max-width: 100%;
}
p {
  font-weight: bold;
  margin: 0;
  padding: 0;
}
</style>

<script>
export default {
  name: "apropos",
  data() {
    return {
      departement: "",
    };
  },
  computed: {
    lang() {
      let langIndex = this.$store.state.lang;
      return this.$store.state.languages[langIndex];
    },
    departements() {
      return this.$store.state.departements;
    },
  },
  methods: {
    inArray: function (needle, haystack) {
      var length = haystack.length;
      for (var i = 0; i < length; i++) {
        if (haystack[i] == needle) return true;
      }
      return false;
    },
  },
};
</script>